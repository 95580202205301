import {Game} from "@/models";

export const gamesList: Game[] = [
    {
        path: "eco-park",
        created: 'today',
        name: "Eco Park",
        splashImageUrl: "/games/eco-park/title-screen.png",
        images:[ "/games/eco-park/title-screen.png"],
        description:"Work your way down the food web! Start from a mighty wolf, dodge predators, and try your best to end up a little flower!",
        sections: [],
        buildPath: '/games/eco-park/build/index.html'
    }
]
