
import {Options, Vue} from 'vue-class-component';
import {Game} from "@/models";
import router from "@/router";
import GamesCard from "@/components/GamesCard.vue";

@Options({
  components: {
    GamesCard
  },
  props: {
    games: Array
  }
})
export default class GamesBar extends Vue {
  private games!: Game[];

  private route(destination: string) {
    router.push(destination);
  }
}
