
import {Options, Vue} from 'vue-class-component';
import router from "@/router";

@Options({
  components: {
  },
  props: {
  }
})
export default class GamesBar extends Vue {

  private route(destination: string) {
    router.push(destination);
  }
}
