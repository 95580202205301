
import {Options, Vue} from 'vue-class-component';
import HelloWorld from '@/components/HelloWorld.vue';
import GamesBar from "@/components/GamesBar.vue";
import router from "@/router";
import {gamesList} from "@/data/games"; // @ is an alias to /src

@Options({
  components: {
    GamesBar,
    HelloWorld,
  },
})
export default class HomeView extends Vue {
  private localGamesList = gamesList;
  private splash = "";

  mounted() {
    let img = this.getSplashImg();
    console.log("YO IMAGE" + img);
    this.splash = this.getSplashImg();
  }

  private route(destination: string) {
    router.push(destination);
  }

  private getSplashImg() {
    return this.localGamesList ? this.localGamesList[0].splashImageUrl : '';
  }

}
