<template>
  <div class="wrapper">
    <top-bar>
      <nav>
        <router-link to="/" aria-label="Home">Home</router-link>
        <router-link to="/games" aria-label="Games">Games</router-link>
        <router-link to="/about" aria-label="About">About</router-link>
        <router-link to="/contact" aria-label="Contact">Contact</router-link>
      </nav>
    </top-bar>
    <div id="scroll">
    <content>
      <router-view/>
    </content>
    <lower-bar/>
    </div>
  </div>
</template>

<script>
import {Options, Vue} from 'vue-class-component';
import TopBar from "@/components/TopBar";
import Content from "@/components/Content";
import LowerBar from "@/components/LowerBar";
import router from "@/router";

@Options({
  components: {
    LowerBar,
    TopBar,
    Content
  }
})
export default class App extends Vue {
}
</script>

<style>

@font-face {
  font-family: "Lemon Milk";
  src: url(~@/assets/fonts/LEMONMILK-Regular.otf);
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Lemon Milk";
  src: url(~@/assets/fonts/LEMONMILK-Bold.otf);
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Lemon Milk";
  src: local("Lemon Milk"),url(~@/assets/fonts/LEMONMILK-Medium.otf);
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Lemon Milk";
  src: url(~@/assets/fonts/LEMONMILK-MediumItalic.otf);
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Lemon Milk";
  src: url(~@/assets/fonts/LEMONMILK-Light.otf);
  font-weight: 300;
  font-style: normal;
}

html, body {
  margin: 0;
  height: 100%;
  width: 100%;
}

#app {
  font-family: Sen, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --lemon-milk: "Lemon Milk", Sen, sans-serif;
  --sans: Sen, Helvetica, Arial, sans-serif;

  --accent: #FF2000;
  --accent-t: #ff200036;

  color: #2c3e50;
  height: 100%;
}

a[href] {
  color: var(--accent);
}

.wrapper {
  background: white;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-flow: column;
}

nav {
  display: flex;
  justify-content: space-evenly;
}

nav a {
  color: var(--accent);
  text-transform: uppercase;
  font-weight: bold;
  text-decoration: none;
  margin: 20px;
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

nav a[href]{
  color: transparent;
}

nav a::after{
  position: absolute;
  transition: 0.1s ease-out;
  color: var(--accent);
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  content: attr(aria-label);
}

nav a:hover::after {
  transform: scale(0.85);
}

nav a.router-link-exact-active::after {
  color: white;
}

h1 {
  text-transform: uppercase;
  font-size: 1.5em;
  font-family: var(--lemon-milk);
  font-weight: 500;
  letter-spacing: 2px;
  color: var(--accent);
}

h2 {
  font-size: 1.25em;
  font-family: var(--lemon-milk);
  letter-spacing: 1px;
}

.md-card{
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}
.md-card:hover{
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}
button.btn{

  border-radius: 16px;
  padding: 8px 16px;
  border: none;
  margin-left: 10px;
  font-family: var(--lemon-milk);
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
  color: white;
  background: black;
}


button.btn:hover{
  cursor:pointer;
  background: var(--accent);
}

h1.hl{

  text-shadow: 4px 4px var(--accent);
  margin-top: auto;
  color: white;
  font-weight: bold;
  font-size: 4em;
  margin-bottom: 0;
  background: black;
  width: max-content;
  margin-top: .5em;
  padding: 10px 20px;
}
</style>
<style scoped>

#scroll {
  overflow-y: auto;
  flex:1;
  display: flex;
  flex-flow: column;
}


</style>
