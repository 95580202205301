
import {Options, Vue} from 'vue-class-component';
import {Game} from "@/models";

@Options({
  props: {
    game: Object
  }
})
export default class GamesCard extends Vue {
  private game!: Game;
}
