import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-373dc004"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "home" }
const _hoisted_2 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_games_bar = _resolveComponent("games-bar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createElementBlock("div", {
      id: "mainSplashImage",
      style: _normalizeStyle(`background-image:url(${_ctx.splash})`),
      key: _ctx.splash
    }, null, 4)),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_games_bar, { games: _ctx.localGamesList }, null, 8, ["games"])
    ])
  ]))
}