import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import NotFound from '../views/NotFound.vue'

import {gamesList} from "@/data/games";
import {Game} from "@/models";

declare module 'vue-router' {
    interface RouteMeta {
        game?: Game
    }
}

const routes: Array<RouteRecordRaw> = [
    {path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound},
    {
        path: '/',
        name: 'home',
        component: HomeView,
    },
    {
        path: '/about',
        name: 'about',
        meta: {
            title: "About"
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    },
    {
        path: '/contact',
        meta: {
            title: "Contact"
        }, name: 'contact',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/ContactView.vue')
    },
    {
        path: '/games',
        meta: {
            title: "Games"
        }, name: 'games',
        component: () => import(/* webpackChunkName: "about" */ '../views/GamesHomeView.vue')
    },
    {
        path: '/games/:gamePath',
        name: ':gamePath',
        component: () => import(/* webpackChunkName: "about" */ '../views/GameView.vue'),

    },
    {
        path: '/play/:gamePath',
        name: 'play :gamePath',
        component: () => import(/* webpackChunkName: "about" */ '../views/PlayView.vue'),

    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
