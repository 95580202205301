/* eslint-disable semi */
import {createApp} from 'vue'
import App from './App.vue'
import router from './router'


const app = createApp(App);
app.use(router);
app.mount('#app');


const DEFAULT_TITLE = "Arvarit";
router.afterEach((to) => {
    document.title = to.meta.title ? DEFAULT_TITLE + " - " + to.meta.title : DEFAULT_TITLE;
});