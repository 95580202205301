import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2920d565"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "games-bar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_games_card = _resolveComponent("games-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.games, (game) => {
      return (_openBlock(), _createBlock(_component_games_card, {
        class: "game md-card",
        onClick: ($event: any) => (_ctx.route('games/' + game.path)),
        key: game.path,
        game: game
      }, null, 8, ["onClick", "game"]))
    }), 128))
  ]))
}